<template>
    <div 
        class="maintenance-monitoring-container"
    >
        <div id="maintenance-monitoring-map" class="maintenance-monitoring-map"></div>
        <el-input
            class="monitoring-search-input"
            placeholder="输入电池ID或机构名称"
            v-model="keyValue"
            @change="keyValueChange"
        >
        </el-input>
        <img 
            class="refresh-icon" 
            :src="require('@/assets/img/monitor-refresh.png')"
            @click="refreshMap"
            @dblclick.stop=""
        />
         <el-popover
            placement="left-start"
            width="300"
            trigger="click"
        >
            <div class="group-tree-content">
                <el-tree 
                    :data="treeData" 
                    :props="defaultProps" 
                    default-expand-all
                    @node-click="onNodeClick"
                ></el-tree>
            </div>
            <img slot="reference" class="group-tree-icon" :src="require('@/assets/img/monitor-tree.png')" @dblclick.stop=""/>
        </el-popover>
        <!-- <el-popover
            placement="bottom"
            title="报警电池"
            width="546"
            trigger="click"
        >
            <div class="battery-list-container">
                <BatteryList :alarmBatteryList="alarmBatteryList" @serchOneBattery="onSerchOneBattery"/>
            </div>
            <div 
                slot="reference" 
                class="alarm-button"
                @click="getAlarmBatteryList"
            >
                报警（{{ batteryStatusTotal.alarmCount }}）
            </div>
        </el-popover> -->
        <div class="filter-buttons" @dblclick.stop="">
            <div class="all-button" 
                @click="changeFilterStatus('')"
                @dblclick="jumpBatteryManage"
            >
                全部（{{ batteryStatusTotal.totalCount }}）
            </div>
            <div 
                class="alarm-button"
                @click="changeFilterStatus([2])"
                @dblclick="jumpHistoryAlarm('alarm')"
            >
                报警（{{ batteryStatusTotal.alarmCount }}）
            </div>
            <div 
                class="pre-alarm-button" 
                @click="changeFilterStatus([1])"
                @dblclick="jumpHistoryAlarm('preAlarm')"
            >
                预警（{{ batteryStatusTotal.preAlarmCount }}）
            </div>
            <div 
                class="offline-button" 
                @click="changeFilterStatus([4,0,null])"
                @dblclick="jumpOfflinePage"
            >
                离线（{{ batteryStatusTotal.offlineCount }}）
            </div>
            <div 
                class="site-button" 
                @dblclick="jumpSiteList"
            >
                站点（{{ siteCount }}）
            </div>
        </div>
        <BatteryDetailModal :dialogVisible="dialogVisible" :fillData="batteryDetailInfo" @close="onCloseHandel"/>
    </div>
</template>

<script>
import L from "leaflet/dist/leaflet";
import "leaflet.chinatmsproviders";
import "leaflet/dist/leaflet.css";
import './Index.less';
import axios from '@/utils/axios';
import "leaflet.markercluster/dist/MarkerCluster.css"
import "leaflet.markercluster/dist/MarkerCluster.Default.css"
import "leaflet.markercluster";
import onlineIconPng from '@/assets/img/online-battery-icon.png';
import alarmIconPng from '@/assets/img/alarm-battery-icon.png';
import prealarmIconPng from '@/assets/img/prealarm-battery-icon.png';
import offLineIconPng from '@/assets/img/offline-battery-icon.png';
import siteIconPng from '@/assets/img/site-icon.png';
import BatteryList from './components/BatteryList'
import BatteryDetailModal from './components/BatteryDetailModal';
const onlineIcon = L.icon({
    iconUrl: onlineIconPng,
});
const alarmIcon = L.icon({
    iconUrl: alarmIconPng,
});
const prealarmIcon = L.icon({
    iconUrl: prealarmIconPng,
});
const offlineIcon = L.icon({
    iconUrl: offLineIconPng,
});
const siteIcon = L.icon({
    iconUrl: siteIconPng,
});
export default {
    name: 'MaintenanceMonitoring',
    data() {
        return {
            map: null,
            markers: null,
            defaultProps: {
                children: 'childs',
                label: 'orgName',
            },
            treeData: [],
            keyValue: '', 
            filterStatus: '', // 筛选的状态
            selectOrgCode: '',
            dialogVisible: false,
            batteryPointMap: { }, // 电池点数据map
            batteryStatusTotal: {
                alarmCount: 0,
                offlineCount: 0,
                onlineCount: 0,
                totalCount: 0,
                preAlarmCount: 0
            },
            batteryDetailInfo: {
                batteryCode: '',
                batteryStatus: '',
                batteryType: '',
                faultDesc: '',
                faultLevel: '',
                faultNamem: '',
                lastTime: '',
                latitude: '',
                location: '',
                longitude: '',
                reportTime: '',
                siteName: ''
            }, // 电池详情
            timer: null,
            alarmBatteryList: [],
            loading: false,
            siteCount: 0
        }
    },
    components: {
        BatteryList,
        BatteryDetailModal
    },
    computed: {
        userInfo() {
            return this.$store.state.currentUserInfo;
        }
    },
    methods: {
        initMap() {
            const normalm = L.tileLayer.chinaProvider("GaoDe.Normal.Map", {
                maxZoom: 18,
                minZoom: 1,
            });
            const normal = L.layerGroup([normalm]);
            const map = L.map("maintenance-monitoring-map", {
                center: [40.125721, 116.320473],
                zoom: 5,
                layers: [normal],
                zoomControl: false,
            });
            this.map = map;
        },
        initClusterGroup() {
            // 聚合对象
            const markers = L.markerClusterGroup({
                iconCreateFunction: (cluster) => {
                    const childMarks = cluster.getAllChildMarkers();
                    const batteryArray = childMarks.map(item => item.options.pointInfo);
                    const {
                        alarmCount,
                        preAlarmCount
                    } = this.getClusterBatterAlarmNumber(batteryArray)
                    return L.divIcon({ 
                        html: `<div class="cluster-icon cluster-icon-${this.getClusterBgClass(batteryArray)}">
                            <div class="cluster-count">${cluster.getChildCount()}</div>
                            <div class="cluster-pop">
                                <div class="info-item">
                                    <span class="lable">电池数量：</span>
                                    <span>${cluster.getChildCount()}</span>
                                </div>
                                <div class="info-item">
                                    <span class="lable">报警电池数量：</span>
                                    <span style="color: rgba(254, 50, 50, 1)">${alarmCount}</span>
                                </div>
                                <div class="info-item">
                                    <span class="lable">预警电池数量：</span>
                                    <span style="color: rgba(242, 167, 69, 1)">${preAlarmCount}</span>
                                </div>
                                <div class="info-item">
                                    <span class="lable">归属：</span>
                                    <span style="color: rgba(0, 150, 255, 1)">${this.userInfo.org.orgName}</span>
                                </div>
                            </div>
                        </div>`
                    });
                }
            });
            this.markers = markers;
            this.map.addLayer(markers);
        },
        clearAllPoint() {
            this.markers.clearLayers();
            this.batteryPointMap = {};
        },
        onCloseHandel() {
            this.dialogVisible = false;
        },
        keyValueChange(value) {
            this.keyValue = value;
            this.clearAllPoint();
            this.getBatteryList();
        },
        onNodeClick(data) {
            this.selectOrgCode = data.orgCode;
            this.clearAllPoint();
            this.getBatteryList();
        },
        refreshMap() {
            this.keyValue = '';
            this.selectOrgCode = '';
            this.filterStatus = '';
            this.clearAllPoint();
            this.getBatteryList();
        },
        changeFilterStatus(status) {
            this.clearAllPoint();
            this.filterStatus = status;
            this.$nextTick(() => {
                this.getBatteryList();
            });
        },
        async getGroupTree() {
            const userinfo = localStorage.getItem('currentUser')
            const orgId = JSON.parse(userinfo).orgId;
            const rs = await axios.get(`/api/org/getOrgTree/${Number(orgId)}`);
            this.treeData = [rs];
        },
        async getBatteryList() {
            const { keyValue, selectOrgCode, filterStatus } = this;
            this.loading = true;
            const rs = await axios.get('/api/monitor/map/search', { keyWord: keyValue, orgCode: selectOrgCode }).catch(err => {
                this.$message.error(err);
                this.loading = false
            });
            const result = [];
            rs.forEach(item => {
                if (!filterStatus) {
                    result.push(item);
                } else if (filterStatus.includes(item.batteryStatus)) {
                    result.push(item);
                }
            }); 
            this.createBatteryPoint(result);
            this.loading = false
        },
        async getBatteryStatus() {
            const rs = await axios.get('/api/monitor/map/statusSummary');
            const {
                alarmCount,
                offlineCount,
                onlineCount,
                totalCount,
                preAlarmCount
            } = rs;
            this.batteryStatusTotal = {
                alarmCount,
                offlineCount,
                onlineCount,
                totalCount,
                preAlarmCount
            };
        },
        async getAlarmBatteryList() {
            const rs = await axios.get('/api/monitor/map/listByStatus', { status: 2 });
            this.alarmBatteryList = rs;
        },
        createBatteryPoint(batteryList) {
            const batteryPointMap = {};
            batteryList.forEach(element => {
                const {
                    batteryCode,
                    batteryStatus,
                    faultDesc,
                    faultLevel,
                    faultName,
                    id,
                    lastTime,
                    latitude,
                    longitude,
                    ownership,
                } = element;
                if (!latitude || !longitude) {
                    return;
                }
                if (latitude === 1 || latitude === 0 || longitude === 1 || longitude === 0) {
                    return;
                }
                const batteryPoint = this.batteryPointMap[batteryCode]; // 是否已存在
                if (batteryPoint) {
                    // 存在
                    const { marker } = batteryPoint;
                    marker.setLatLng(L.latLng(
                        latitude,
                        longitude
                    ));
                    batteryPointMap[batteryCode] = { batteryInfo: element, marker };
                } else {
                    // 不存在 创建
                    const point = L.latLng(
                        latitude,
                        longitude
                    );
                    const marker =  L.marker(point, { 
                        icon: this.getBatteryIcon(batteryStatus),
                        pointInfo: element,
                        autoPan: true
                    });
                    marker.on('click', () => {
                        this.getBatteryDetail(element.batteryCode);
                        this.dialogVisible = true;
                    });
                    marker.on('mouseover', () => {
                        marker.openPopup();
                    });
                    marker.on('mouseout', () => {
                        marker.closePopup();
                    });
                    // 电池pop
                    const popup = L.popup()
                    .setContent(`<div class="battery-pop">
                        <div class="info-item">
                            <span class="lable">电池状态：</span>
                            <span class="value">${this.getBatteryStatusStr(batteryStatus)}</span>
                        </div>
                        <div class="info-item">
                            <span class="lable">电池ID：</span>
                            <span class="value">${batteryCode}</span>
                        </div>
                        <div class="info-item">
                            <span class="lable">最后数据时间：</span>
                            <span class="value">${lastTime}</span>
                        </div>
                        <div class="info-item">
                            <span class="lable">归属：</span>
                            <span style="color: rgba(0, 150, 255, 1)" class="value">${ownership}</span>
                        </div>
                    </div>`);
                    marker.bindPopup(popup);
                    this.markers.addLayer(marker);
                    batteryPointMap[batteryCode] = {
                        batteryInfo: element,
                        marker
                    };
                }
            });
            this.batteryPointMap = batteryPointMap;
        },
        getBatteryIcon(status) {
            let icon = prealarmIcon;
            if (status === 1) {
                icon = prealarmIcon;
            } else if (status === 2) {
                icon = alarmIcon
            } else if(status === 3) {
                icon = onlineIcon
            } else {
                icon = offlineIcon;
            }
            return icon;
        },
        getBatteryStatusStr(status) {
            let statusStr = '在线';
            if (status === 1) {
                statusStr = '预警';
            } else if (status === 2) {
                statusStr = '报警'
            } else if(status === 3) {
                statusStr = '在线'
            } else {
                statusStr = '离线';
            }
            return statusStr;
        },
        getClusterBgClass(data) {
            let className= '';
            const item3 = data.find(item => item.batteryStatus === 3);
            if (item3) {
                className = 'online'
                return className;
            }

            const item = data.find(item => (item.batteryStatus === 4 || item.batteryStatus === 0 || item.batteryStatus === null ));
            if (item) {
                className = 'offLine'
                return className;
            }

            const item1 = data.find(item => item.batteryStatus === 2);
            if (item1) {
                className = 'alarm'
                return className;
            }

            const item2 = data.find(item => item.batteryStatus === 1);
            if (item2) {
                className = 'prealarm'
                return className;
            }

            return 'offLine';
        },
        getClusterBatterAlarmNumber(data) {
            let alarmCount = 0;
            let preAlarmCount = 0;
            const alarmItems = data.filter(item => item.batteryStatus === 2);
            alarmCount = alarmItems.length;
            const prealarmItems = data.filter(item => item.batteryStatus === 1);
            preAlarmCount = prealarmItems.length;
            return {
                alarmCount,
                preAlarmCount
            };
        },
        getMapData() {
            this.getBatteryList();
            this.getBatteryStatus();
        },
        getBatteryDetail(batteryCode) {
            axios.get(`/api/monitor/map/batteryDetail/${batteryCode}`).then(rs => {
                this.batteryDetailInfo = rs;
            }).catch(err => {
                this.$message.error(err);
            });
        },
        initTimer() {
            if (this.timer) {
                return;
            }
            this.timer = setInterval(this.getMapData, 30000);
        },
        onSerchOneBattery(batteryCode) {
            this.keyValue = batteryCode;
            this.clearAllPoint();
            this.getBatteryList();
        },
        jumpBatteryManage() {
            this.$router.push('/battery-management')
        },
        jumpHistoryAlarm(type) {
            this.$router.push(`/history-alarm/${type}`);
        },
        jumpSiteList() {
            this.$router.push('/site-management');
        },
        jumpOfflinePage() {
            this.$router.push('/offline-info');
        },
        getSiteList() {
            axios.get('/api/monitor/siteList').then(rs => {
                this.siteCount = rs.length;
                rs.forEach(item => {
                    const { siteAddressLngLat, siteName, siteAddress, orgName,  } = item;
                    const [ lat, lng ] = siteAddressLngLat.split(',');
                    console.log(siteName, siteAddressLngLat)
                    const point = L.latLng(
                        Number(lat),
                        Number(lng)
                    );
                    const marker =  L.marker(point, { 
                        icon: siteIcon,
                        autoPan: true
                    });
                    marker.on('mouseover', () => {
                        marker.openPopup();
                    });
                    marker.on('mouseout', () => {
                        marker.closePopup();
                    });
                    const popup = L.popup()
                    .setContent(`<div class="site-pop">
                        <div class="info-item">
                            <span class="lable">站点名称：</span>
                            <span class="value">${siteName}</span>
                        </div>
                        <div class="info-item">
                            <span class="lable">所属机构：</span>
                            <span class="value">${orgName ? orgName : '-'}</span>
                        </div>
                        <div class="info-item">
                            <span class="lable">站点地址：</span>
                            <span class="value">${siteAddress}</span>
                        </div>
                    </div>`);
                    marker.bindPopup(popup);
                    marker.addTo(this.map);
                });
            }).catch(err => {
                this.$message.error(err);
            });
        }
    },
    mounted() {
        this.initMap();
        this.initClusterGroup();
        this.getGroupTree();
        this.getBatteryList();
        this.getBatteryStatus();
        this.getSiteList();
        this.initTimer();
    },
    beforeDestroy() {
        clearInterval(this.timer, this.getMapData);
        this.timer = null;
    }
}
</script>