<template>
    <el-dialog
        class="battery-detail-modal"
        title="电池详情"
        :visible="dialogVisible"
        width="650px"
        :modal-append-to-body="true"
        @close="onCloseHandel"
    >
        <div class="battery-modal-detail-content">
            <div class="fault-info">
                <div 
                    :class="{'fault-name': true, [getBatteryBgColor(fillData.batteryStatus)]: true}"
                >
                    {{ getBatteryStatusStr(fillData.batteryStatus) }}
                </div>
                <div class="fault-time-info">
                    <div class="alarm-time">
                        <span class="lable">报警时间：</span>
                        <span class="value">{{fillData.reportTime}}</span>
                    </div>
                    <div class="data-last-time">
                        <span class="lable">数据最后上报时间：</span>
                        <span class="value">{{ fillData.lastTime }}</span>
                    </div>
                </div>
            </div>
            <div class="fault-name-info">
                <div v-if="fillData.faultName" class="title">
                    <span class="name">{{ fillData.faultName }}</span>
                    <span class="des">{{ fillData.faultDesc }}</span>
                </div>
                <div class="battery-info">
                    <div class="battery-info-item">
                        <span class="lable">电池类型：</span>
                        <span class="value">{{ fillData.batteryType }}</span>
                    </div>
                    <div class="battery-info-item">
                        <span class="lable">电池品牌：</span>
                        <span class="value">{{ fillData.batteryBrand}}</span>
                    </div>
                    <div class="battery-info-item">
                        <span class="lable">电池ID：</span>
                        <span class="value">{{ fillData.batteryCode }}</span>
                    </div>
                </div>
            </div>
            <div class="gps-info">
                <div class="title">归属站点</div>
                <div class="serve-site-info">
                    <div class="serve-item" v-if="fillData.siteName">
                        <span class="lable">{{fillData.siteName}}</span>
                        <span v-if="latitude && longitude" class="value">{{latitude}},{{longitude}}</span>
                    </div>
                </div>
            </div>
            <CreateFaultModal ref="createFaultModal" class="create-fault-Modal-monitor">
                <div class="order-button" @click="setBatteryCode">派发运维工单</div>
            </CreateFaultModal>
        </div>
    </el-dialog>
</template>

<script>
import CreateFaultModal from '../../work-order-management/fault-rescue/components/CreateFaultModal';
export default {
    name: 'DetailModal',
    components: {
        CreateFaultModal
    },
    props: {
        dialogVisible: {
            type: Boolean,
            default: false
        },
        fillData: {
            type: Object,
            default: () => ({
                batteryCode: '',
                batteryStatus: '',
                batteryType: '',
                faultDesc: '',
                faultLevel: '',
                faultNamem: '',
                lastTime: '',
                latitude: '',
                location: '',
                longitude: '',
                reportTime: '',
                siteName: ''
            })
        }
    },
    methods: {
        onCloseHandel() {
            this.$emit('close');
        },
        setBatteryCode() {
            this.$refs.createFaultModal.setBatterInfo(this.fillData.batteryCode);
        },
        getBatteryStatusStr(status) {
            let statusStr = '在线';
            if (status === 1) {
                statusStr = '预警';
            } else if (status === 2) {
                statusStr = '报警'
            } else if(status === 3) {
                statusStr = '在线'
            } else {
                statusStr = '离线';
            }
            return statusStr;
        },
        getBatteryBgColor(status) {
            let className = 'online';
            if (status === 1) {
                className = 'preAlarm';
            } else if (status === 2) {
                className = 'alarm'
            } else if(status === 3) {
                className = 'online'
            } else {
                className = 'offline';
            }
            return className;
        }
    }
}
</script>

<style lang="less">
    .battery-modal-detail-content {
        padding: 0 30px;
        height: 420px;
        .fault-info {
            height: 40px;
            display: flex;
            align-content: center;
            align-items: center;
            .fault-name {
                width: 80px;
                height: 30px;
                background: #F41B36;
                border-radius: 3px;
                font-size: 14px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 30px;
                text-align: center;
            }
            .online {
                background-color: #16C47B;
            }
            .preAlarm {
                background-color: #F2A745;
            }
            .alarm {
                background-color: #FE3232;
            }
            .offline {
                background-color: #aaa;
            }
            .fault-time-info {
                flex: auto;
                .alarm-time, .data-last-time  {
                    font-size: 14px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    height: 20px;
                    line-height: 20px;
                    text-align: right;
                    color: #666;
                    .value {
                        font-size: 14px;
                        font-family: PingFang SC;
                        font-weight: 400;
                        color: rgba(0, 150, 255, 1);
                    }
                }
            }
        }
        .fault-name-info {
            height: 110px;
            margin-top: 30px;
            .title {
                height: 30px;
                display: flex;
                align-items: center;
                .name {
                    font-size: 16px;
                    font-family: PingFang SC;
                    font-weight: 500;
                    color: #333333;
                }
                .des {
                    margin-left: 20px;
                    font-size: 14px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: #999999;
                }
            }
            .battery-info {
                height: 93px;
                margin-top: 10px;
                background: #F2F2F2;
                border-radius: 3px;
                .battery-info-item {
                    height: 30px;
                    padding-left: 10px;
                    line-height: 30px;
                    font-size: 14px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: #666666;
                }
            }
        }
        .gps-info {
            height: 100px;
            margin-top: 40px;
            .title {
                font-size: 16px;
                font-family: PingFang SC;
                font-weight: 500;
                color: #222222;
            }
            .serve-site-info {
                font-size: 14px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #666666;
                .serve-item {
                    height: 30px;
                    line-height: 30px;
                    .value {
                        margin-left: 20px;
                    }
                }
            }
        }
        .order-button {
            width: 150px;
            height: 30px;
            margin-top: 20px;
            margin-left: 180px;
            background: #0096FF;
            border-radius: 3px;
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 30px;
            text-align: center;
            cursor: pointer;
        }
    }
    .create-fault-Modal-monitor {
    }
</style>